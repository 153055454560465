<template>
  <f7-popup class="shippingselect-popup" :opened="open" :tablet-fullscreen="false" :backdrop="true" @popup:opened="openPopup" @popup:closed="closePopup">
    <f7-page>
      <f7-navbar>
        <f7-nav-title>{{ $t.getTranslation('LBL_SHIPPING_METHODS') }}</f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>
            <font-awesome-icon :icon="['far', 'times']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <!-- <f7-searchbar v-model:value="searchValue" :disable-button="false" :placeholder="$t.getTranslation('LBL_SEARCH_PLACEHOLDER')" :clear-button="true"></f7-searchbar> -->

      <div class="shipping-container">
        <div class="shipping-card shipping-card-popup" @click="selectShipping('CROSS_BORDER')">
          <div class="img-overlay">
            <img loading="lazy" :src="require('@/assets/images/shipping/cross-border.jpg')" />
          </div>
          <h3>{{ $t.getTranslation('LBL_CROSS_BORDER_SHIPPING') }}</h3>
        </div>
        <div class="shipping-card shipping-card-popup" @click="selectShipping('BONDED')">
          <div class="img-overlay">
            <img loading="lazy" :src="require('@/assets/images/shipping/bonded.jpg')" />
          </div>
          <h3>{{ $t.getTranslation('LBL_BONDED_WAREHOUSE_DELIVERY') }}</h3>
        </div>
      </div>
      <f7-button class="reset-button" fill large @click="resetSelection">{{ $t.getTranslation('LBL_RESET') }}</f7-button>
    </f7-page>
  </f7-popup>
</template>

<script>
import { ref, inject, defineAsyncComponent } from 'vue'
import { f7 } from 'framework7-vue'
import { useStore } from '@/store'

export default {
  name: 'ShippingMethodPopupComponent',
  components: {},
  props: {
    open: Boolean
  },
  emits: ['opened', 'closed', 'selected', 'cleared'],
  setup(props, { emit }) {
    const store = useStore()
    const $t = inject('$translation')
    const showPreloader = ref(false)

    const openPopup = () => {
      emit('opened', true)
    }

    const closePopup = () => {
      emit('closed', true)
    }

    const selectShipping = (data) => {
      f7.popup.close()
      emit('selected', data)
    }

    const resetSelection = (data) => {
      f7.popup.close()
      emit('cleared')
      emit('closed', true)
    }

    return {
      openPopup,
      closePopup,
      selectShipping,
      resetSelection
    }
  }
}
</script>

<style scoped>
.shippingselect-popup.popup .navbar .title {
  margin-left: 8px;
}
.shippingselect-popup .shipping-container {
  width: calc(100% - 40px);
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.shippingselect-popup .shipping-container .category-card {
  width: 50%;
}
.shippingselect-popup .reset-button {
  width: calc(100% - 40px);
  margin: 20px auto;
}
.shippingselect-popup.popup {
  --f7-popup-tablet-height: 360px;
}
</style>
